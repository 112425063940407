import {colors} from '@mui/material'
import React from 'react'
import {DrumKit} from './connected/DrumKit'
import {Notebook} from './connected/Notebook'
import {StyledTextBlock, StyledTextBlockContent, StyledTextBlockHeadwr} from './Elements'


export function ConnectedHoriz() {
    const drumBBox = {x: 20, y: 0, width: 411, height: 328}
    const screenBBox = {x: 735, y: 312, width: 594, height: 403}

    return <svg style={{flexGrow: 1}} width={1400}>
        <DrumKit dx={drumBBox.x} dy={drumBBox.y}/>
        <Notebook dx={665} dy={250}/>
        <CableSVG dx={drumBBox.x + 90} dy={drumBBox.y + 226}/>

        <foreignObject x={100} y={drumBBox.y + drumBBox.height + 80} width={drumBBox.width} height={202}>
            <TextBlock1 textAlign="center"/>
        </foreignObject>

        <foreignObject x={screenBBox.x} y={screenBBox.y - 232 - 40} width={344} height={232}>
            <TextBlock2/>
        </foreignObject>
    </svg>
}


function CableSVG({dx, dy}: { dx: number, dy: number }) {
    const c1 = 'c0,46, 26,120, 87,130'

    const c2 = 'c77,13, 119,-37, 150,-78'

    const c3 = 'c18,-24, 24,-44, 16,-47'
    const c4 = 'c-8,-3, -15,10, -16,48'

    const c5 = 'c4,111, 51,140, 181,116'

    const c6 = 'c131,-24, 249,-199, 165,-242'
    const c7 = 'c-84,-43, -156,131, -130,256'
    const c8 = 'c16,58, 73,130, 162,123'

    return <g id="cable" fill="none" transform={`translate(${dx}, ${dy})`}>
        <USB dx={dx + 502} dy={dy + 52}/>
        <path
            d={`M0,0 ${c1} ${c2} ${c3}${c4} ${c5} ${c6} ${c7} ${c8}`}
            stroke="#FFC107" strokeWidth="4"/>
    </g>
}

function USB({dx, dy}: { dx?: number, dy?: number }) {
    // width="73" height="48"
    return <g transform={`translate(${dx}, ${dy})`}>
        <rect x="67.6347" y="5.51801" width="28" height="22" transform="rotate(82.404 67.6347 5.51801)"
              stroke="#FFC107"/>
        <rect x="59.8041" y="11.1021" width="7" height="6" transform="rotate(82.404 59.8041 11.1021)" fill="#FFC107"/>
        <rect x="61.3904" y="22.9967" width="7" height="6" transform="rotate(82.404 61.3904 22.9967)" fill="#FFC107"/>
        <path
            d="M45.5963 2.9082L50.7516 41.566L17.05 46.0603C10.4807 46.9364 4.44511 42.3212 3.56905 35.7519L1.58623 20.8835C0.710168 14.3143 5.32541 8.27865 11.8947 7.40259L45.5963 2.9082Z"
            fill="#FFC107"/>
    </g>

}

export function ConnectedVert() {
    const width = 542
    const drumBBox = {x: 0, y: 0, width: 542, height: 328}
    const screenBBox = {x: 730, y: 600, width: 594, height: 403}
    return <svg style={{flexGrow: 1, display: 'block', margin: '0 auto'}} width={542} height={1024}>
        <DrumKit dx={drumBBox.x} dy={0}/>
        <Notebook dx={-100} dy={600}/>
        <CableVertSVG dx={90} dy={226}/>

        <foreignObject x={(width - 544) / 2} y={drumBBox.y + drumBBox.height + 40} width={554} height={140}>
            <TextBlock1 textAlign="center"/>
        </foreignObject>

        <foreignObject x={(width - 544) / 2} y={screenBBox.y + screenBBox.height + 40} width={554} height={140}>
            <TextBlock2 textAlign="center"/>
        </foreignObject>
    </svg>
}

function TextBlock1({textAlign}: { textAlign?: 'left' | 'center' | 'right' }) {
    return <StyledTextBlock sx={{textAlign}} data-xmlns="http://www.w3.org/1999/xhtml">
        <StyledTextBlockHeadwr sx={{color: colors.pink[400]}}>Kezdő és
            haladó <wbr/>dobosoknak</StyledTextBlockHeadwr>
        <StyledTextBlockContent>A <b>dobolj.hu segítségével játszva tanulhatsz dobolni</b>.
            Lépésenként tanít új ritmusokat, segít, hogy <b>pontosabban</b> és <b>magabiztosabban</b> játssz.
            MIDI kapcsolaton kommunikál az elektromos doboddal, élőben rögzíti és elemzi a játékodat.
        </StyledTextBlockContent>
    </StyledTextBlock>
}

function TextBlock2({textAlign}: { textAlign?: 'left' | 'center' | 'right' }) {
    return <StyledTextBlock sx={{textAlign}} data-xmlns="http://www.w3.org/1999/xhtml">
        <StyledTextBlockHeadwr sx={{color: colors.lightBlue[400]}}>gyors fejlődés</StyledTextBlockHeadwr>
        <StyledTextBlockContent>A <b>dobolj.hu</b> segít abban, hogy értelmezd a dobkottát.
            Azonnal mutatja, ha melléütöttél, sietsz, késel, vagy kihagysz egy ütést.
            Megmutatja, hogy fejlődsz és kiválasztja a téged legjobban fejlesztő gyakorlatokat.
        </StyledTextBlockContent>
    </StyledTextBlock>
}


function CableVertSVG({dx, dy}: { dx: number, dy: number }) {
    const c1 = 'c2,33,-10,100,-23,124'
    const c2 = 'c-8,16,-21,4,-15,-9'
    const c3 = 'c39,-82,178,-107,252,-70'
    const c4 = 'c73,33,92,148,26,263'
    const c5 = 'c-66,115,-271,167,-283,74'
    const c6 = 'c-12,-93,175,-103,285,-36'
    const c7 = 'c49,34,97,112,61,193'
    return <g id="cable" fill="none" transform={`translate(${dx}, ${dy})`}>
        <g transform={'rotate(10)'}>
            <USB dx={dx} dy={dy + 502}/>
        </g>
        <path
            d={`M0,0 ${c1} ${c2} ${c3} ${c4} ${c5} ${c6} ${c7}`}
            stroke="#FFC107" strokeWidth="4"/>
    </g>
}