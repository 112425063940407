import {styled} from '@mui/material'
import React from 'react'


export const StyledTextBlock = styled('div')(({theme}) => ({
    position: 'fixed'
}))

export const StyledTextBlockHeadwr = styled('div')(({theme}) => ({
    fontFamily: 'Bebas Neue',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '2rem',
    lineHeight: 1.2,
}))


export const StyledTextBlockContent = styled('div')(({theme}) => ({
    paddingTop: '0.5em',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: 1.5,
}))
