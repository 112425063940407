import {GetServerSideProps, GetServerSidePropsContext} from 'next'
import {Session, User} from 'next-auth'
import {getSession} from 'next-auth/react'
import ErrorPage from 'next/error'
import {Redirect} from 'next/types'
import {ParsedUrlQuery} from 'querystring'
import React, {FunctionComponent, ReactNode} from 'react'
import {AuthorizerFunction} from './authorization'

export * from './isAdmin'

type AnyRecord = {
    [key: string]: any
}


export function ProtectPage<P extends AnyRecord>(page: FunctionComponent<P>): FunctionComponent<P> {
    const component = (props: Protected<P>) => {
        if (!isUserAuthorized(props)) {
            return <ErrorPage statusCode={403} title="Ehhez a tartalomhoz nincs jogosultságod"/>
        }

        return page(props)
    }

    component.displayName = page.displayName ?? page.name

    return component
}


export function ProtectedPage<P extends AnyRecord>(child: (p: P) => ReactNode) {
    const component = (props: Protected<P>) => {
        if (!isUserAuthorized(props)) {
            return <ErrorPage statusCode={403} title="Ehhez a tartalomhoz nincs jogosultságod"/>
        }

        return child(props)
    }

    component.displayName = 'ProtectedPage'

    return component
}


/**
 * To be used in getServerSideProps
 * @param getProps
 * @param authorizer
 * @param context
 * @param object
 */


export async function getProtectedServerSideProps<T extends AnyRecord>(
    getProps: (session: Session | null, user: User | null) => T,
    authorizer: AuthorizerFunction,
    context: GetServerSidePropsContext<ParsedUrlQuery, string | false | object | undefined>,
    object?: any): Promise<{ props: Protected<T> } | { redirect: Redirect } | { notFound: true }> {
    const session = await getSession(context)
    const isAuthorized = await authorizer(session, object)

    if ((session == null || session?.user == null) && !isAuthorized) {
        return {
            redirect: {
                destination: `/api/auth/signin?callbackUrl=${context.resolvedUrl}`,
                permanent: false
            }
        }
    }

    if (!isAuthorized) {
        return {
            props: {
                isAuthorized
            }
        }
    }

    return {props: await getProps(session, session?.user)}
}


/**
 * To be used on pages with getProtectedServerSideProps
 */
export type Protected<T extends AnyRecord> = { isAuthorized: false } | T
export type ProtectedPageProps<G> = G extends GetServerSideProps<Protected<infer P>>
    ? P
    : G extends GetServerSideProps<Protected<Promise<infer P>>>
        ? P
        : never

export function isUserAuthorized<T extends AnyRecord>(props: Protected<T>): props is T {
    return !('isAuthorized' in props) || props.isAuthorized
}

export const ServerSideNotFound = {notFound: true as true}