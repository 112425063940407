import React from 'react'

export function DrumModule({dx, dy}: { dx?: number, dy?: number }) {
    // width: 104, height: 111
    return <g transform={`translate(${dx}, ${dy})`}>
        <g fill="#ECEFF1" stroke="#607D8B" strokeWidth="2">
            <path
                d="M4 22C4 20.3431 5.34315 19 7 19H100C101.657 19 103 20.3431 103 22V63V104C103 105.657 101.657 107 100 107H7C5.34315 107 4 105.657 4 104V63V22Z"/>
            <path d="M12 63H24V102C24 103.105 23.1046 104 22 104H14C12.8954 104 12 103.105 12 102V63Z"/>
            <path d="M24 63H36V102C36 103.105 35.1046 104 34 104H26C24.8954 104 24 103.105 24 102V63Z"/>
            <path d="M36 63H47V102C47 103.105 46.1046 104 45 104H38C36.8954 104 36 103.105 36 102V63Z"/>
            <path d="M47 63H59V102C59 103.105 58.1046 104 57 104H49C47.8954 104 47 103.105 47 102V63Z"/>
            <path d="M59 63H71V102C71 103.105 70.1046 104 69 104H61C59.8954 104 59 103.105 59 102V63Z"/>
            <path d="M71 63H83V102C83 103.105 82.1046 104 81 104H73C71.8954 104 71 103.105 71 102V63Z"/>
            <path d="M83 63H95V102C95 103.105 94.1046 104 93 104H85C83.8954 104 83 103.105 83 102V63Z"/>
        </g>
        <g fill="#607D8B">
            <path d="M21 64H27V86C27 86.5523 26.5523 87 26 87H22C21.4477 87 21 86.5523 21 86V64Z"/>
            <path d="M33 64H39V86C39 86.5523 38.5523 87 38 87H34C33.4477 87 33 86.5523 33 86V64Z"/>
            <path d="M44 64H50V86C50 86.5523 49.5523 87 49 87H45C44.4477 87 44 86.5523 44 86V64Z"/>
            <path d="M68 64H74V86C74 86.5523 73.5523 87 73 87H69C68.4477 87 68 86.5523 68 86V64Z"/>
            <path d="M80 64H86V86C86 86.5523 85.5523 87 85 87H81C80.4477 87 80 86.5523 80 86V64Z"/>
            <rect x="76" y="15" width="8" height="3"/>
            <rect y="27" width="3" height="8"/>
            <rect x="86" y="15" width="8" height="3"/>
            <rect x="86" y="108" width="8" height="3"/>
        </g>
        <mask id="path-19-inside-1_33_10" fill="white">
            <rect x="82" y="27" width="12" height="12" rx="1"/>
        </mask>
        <rect x="82" y="27" width="12" height="12" rx="1" fill="#FFCA28" stroke="#607D8B" strokeWidth="4"
              mask="url(#path-19-inside-1_33_10)"/>
        <mask id="path-20-inside-2_33_10" fill="white">
            <rect x="82" y="42" width="12" height="12" rx="1"/>
        </mask>
        <rect x="82" y="42" width="12" height="12" rx="1" fill="#80D8FF" stroke="#607D8B" strokeWidth="4"
              mask="url(#path-20-inside-2_33_10)"/>
        <mask id="path-21-inside-3_33_10" fill="white">
            <rect x="67" y="27" width="12" height="12" rx="1"/>
        </mask>
        <rect x="67" y="27" width="12" height="12" rx="1" fill="#CFD8DC" stroke="#607D8B" strokeWidth="4"
              mask="url(#path-21-inside-3_33_10)"/>
        <mask id="path-22-inside-4_33_10" fill="white">
            <rect x="67" y="42" width="12" height="12" rx="1"/>
        </mask>
        <rect x="67" y="42" width="12" height="12" rx="1" fill="#FFAB91" stroke="#607D8B" strokeWidth="4"
              mask="url(#path-22-inside-4_33_10)"/>
        <mask id="path-23-inside-5_33_10" fill="white">
            <rect x="52" y="27" width="12" height="12" rx="1"/>
        </mask>
        <rect x="52" y="27" width="12" height="12" rx="1" fill="#F06292" stroke="#607D8B" strokeWidth="4"
              mask="url(#path-23-inside-5_33_10)"/>
        <mask id="path-24-inside-6_33_10" fill="white">
            <rect x="52" y="42" width="12" height="12" rx="1"/>
        </mask>
        <rect x="52" y="42" width="12" height="12" rx="1" fill="#69F0AE" stroke="#607D8B" strokeWidth="4"
              mask="url(#path-24-inside-6_33_10)"/>
        <path d="M14 32H41" stroke="#607D8B" strokeWidth="2" strokeLinecap="round"/>
        <path d="M14 41H41" stroke="#607D8B" strokeWidth="2" strokeLinecap="round"/>
        <path d="M14 50H41" stroke="#607D8B" strokeWidth="2" strokeLinecap="round"/>
        <rect x="33" y="29" width="2" height="6" fill="#607D8B"/>
        <rect x="22" y="38" width="2" height="6" fill="#607D8B"/>
        <rect x="30" y="47" width="2" height="6" fill="#607D8B"/>
        <path
            d="M3.574 0.199999H5.772L6.752 7.214H6.78L7.76 0.199999H9.958V10H8.502V2.58H8.474L7.354 10H6.066L4.946 2.58H4.918V10H3.574V0.199999ZM11.3172 0.199999H12.8572V10H11.3172V0.199999ZM14.2206 0.199999H16.5726C17.3379 0.199999 17.9119 0.405333 18.2946 0.816C18.6772 1.22667 18.8686 1.82867 18.8686 2.622V7.578C18.8686 8.37133 18.6772 8.97333 18.2946 9.384C17.9119 9.79467 17.3379 10 16.5726 10H14.2206V0.199999ZM16.5446 8.6C16.7966 8.6 16.9879 8.52533 17.1186 8.376C17.2586 8.22667 17.3286 7.984 17.3286 7.648V2.552C17.3286 2.216 17.2586 1.97333 17.1186 1.824C16.9879 1.67467 16.7966 1.6 16.5446 1.6H15.7606V8.6H16.5446ZM20.1181 0.199999H21.6581V10H20.1181V0.199999Z"
            fill="#607D8B"/>
    </g>

}
