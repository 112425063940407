import {AppHeader} from '@ezchops/base-components/dist/components/layout'
import {colors, styled} from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {useSession} from 'next-auth/react'
import React from 'react'

export function Hero() {
    const {data: session, status: sessionState} = useSession()

    return <Container disableGutters={true} maxWidth={false}>
        <HeroSection direction="column">
            <Box flexGrow={0}><AppHeader menuItems={[{href: '/kurzusok', label: 'kurzusok'}]}/></Box>
            <Stack direction="column" flexGrow={1} spacing={4} justifyContent="center" alignItems="center">
                <Typography variant="h1" sx={{fontSize: '7.5rem', color: colors.common.white, textAlign: 'center'}}>
                    Automata dobtanár otthonra
                </Typography>
                {/*<Typography variant="body1" sx={{color: ezColors.white, textAlign: 'center'}}>*/}
                {/*    Minden zenész kezdő volt*/}
                {/*    egyszer. Te is eljuthatsz a színpadig. <br/>A kulcs a gyakorlás*/}
                {/*    - és tudjuk, hogy ez nehéz.*/}
                {/*    A dobolj.hu segít, hogy a gyakorlás könnyű, élvezetes és hatékony legyen!*/}
                {/*</Typography>*/}
                <Button sx={{py: 3, px: 6, fontWeight: 'bold'}}
                        variant="contained" size="large" href="#featured">
                    Próbáld ki!
                </Button>
            </Stack>
        </HeroSection>
    </Container>
}

const HeroSection = styled(Stack)(({theme}) => ({
    backgroundImage: 'url(/images/background/hero.jpg)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100vh'
}))
