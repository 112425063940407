import React from 'react'
import {DrumModule} from './DrumModule'

export function DrumKit({dx, dy}: { dx?: number, dy?: number }) {
    // width="542" height="328"
    return <>
        <DrumModule dx={dx ?? 0} dy={115}/>
        <g transform={`translate(${(dx ?? 0) + 130}, ${dy})`} id="connected-drumkit">
            <path d="M72 20V0" stroke="#607D8B" strokeWidth="2"/>
            <path
                d="M83 25C83 29.8076 78.2536 34 72 34C65.7464 34 61 29.8076 61 25C61 20.1924 65.7464 16 72 16C78.2536 16 83 20.1924 83 25Z"
                fill="#FFC107" stroke="#607D8B" strokeWidth="2"/>
            <path
                d="M138 34C138 34.6189 137.704 35.3253 136.924 36.1218C136.141 36.9218 134.934 37.7416 133.292 38.5529C130.011 40.1736 125.199 41.6611 119.172 42.9206C107.132 45.4363 90.4541 47 72 47C53.5459 47 36.8676 45.4363 24.8284 42.9206C18.8005 41.6611 13.9887 40.1736 10.7081 38.5529C9.06591 37.7416 7.85908 36.9218 7.07566 36.1218C6.29562 35.3253 6 34.6189 6 34C6 33.3811 6.29562 32.6747 7.07566 31.8782C7.85908 31.0782 9.06591 30.2584 10.7081 29.4471C13.9887 27.8264 18.8005 26.3389 24.8284 25.0794C36.8676 22.5637 53.5459 21 72 21C90.4541 21 107.132 22.5637 119.172 25.0794C125.199 26.3389 130.011 27.8264 133.292 29.4471C134.934 30.2584 136.141 31.0782 136.924 31.8782C137.704 32.6747 138 33.3811 138 34Z"
                fill="#FFC107" stroke="#607D8B" strokeWidth="2"/>
            <path
                d="M72 32L72.0001 268M72.0001 328L72 307M128 324L72.0001 268M72.0001 268L44.0001 296M72.0001 268L72 307M16 324L44.0001 296M44.0001 296L72 307M72 307L99.5 296"
                stroke="#607D8B" strokeWidth="2"/>
            <rect x="68" y="4" width="8" height="8" fill="#607D8B"/>
            <path d="M8 328H24V320L20 316L8 328Z" fill="#607D8B"/>
            <path d="M136 328H120V320L124 316L136 328Z" fill="#607D8B"/>
            <path d="M76 316H68L64 328H80L76 316Z" fill="#607D8B"/>
            <path d="M4 148H140V268H4L4 148Z" fill="#E91E63" stroke="#607D8B" strokeWidth="2"/>
            <rect x="56" y="149" width="84" height="119" fill="#F06292"/>
            <path d="M4 136H140V148H4L4 136Z" fill="#ECEFF1" stroke="#607D8B" strokeWidth="2"/>
            <path d="M4 268H140V280H4L4 268Z" fill="#ECEFF1" stroke="#607D8B" strokeWidth="2"/>
            <rect x="1" y="154" width="6" height="10" fill="#ECEFF1" stroke="#607D8B" strokeWidth="2"/>
            <path d="M4 144L4 158" stroke="#607D8B" strokeWidth="2"/>
            <rect x="143" y="262" width="6" height="10" transform="rotate(180 143 262)" fill="#ECEFF1" stroke="#607D8B"
                  strokeWidth="2"/>
            <path d="M140 272L140 258" stroke="#607D8B" strokeWidth="2"/>
            <rect x="35" y="154" width="6" height="10" fill="#ECEFF1" stroke="#607D8B" strokeWidth="2"/>
            <path d="M38 144L38 158" stroke="#607D8B" strokeWidth="2"/>
            <rect x="109" y="262" width="6" height="10" transform="rotate(180 109 262)" fill="#ECEFF1" stroke="#607D8B"
                  strokeWidth="2"/>
            <path d="M106 272L106 258" stroke="#607D8B" strokeWidth="2"/>
            <rect x="103" y="154" width="6" height="10" fill="#ECEFF1" stroke="#607D8B" strokeWidth="2"/>
            <path d="M106 144L106 158" stroke="#607D8B" strokeWidth="2"/>
            <rect x="41" y="262" width="6" height="10" transform="rotate(180 41 262)" fill="#ECEFF1" stroke="#607D8B"
                  strokeWidth="2"/>
            <path d="M38 272L38 258" stroke="#607D8B" strokeWidth="2"/>
            <rect x="137" y="154" width="6" height="10" fill="#ECEFF1" stroke="#607D8B" strokeWidth="2"/>
            <path d="M140 144L140 158" stroke="#607D8B" strokeWidth="2"/>
            <rect x="7" y="262" width="6" height="10" transform="rotate(180 7 262)" fill="#ECEFF1" stroke="#607D8B"
                  strokeWidth="2"/>
            <path d="M4 272L4 258" stroke="#607D8B" strokeWidth="2"/>
            <rect x="69" y="154" width="6" height="10" fill="#ECEFF1" stroke="#607D8B" strokeWidth="2"/>
            <path d="M72 144L72 158" stroke="#607D8B" strokeWidth="2"/>
            <rect x="75" y="262" width="6" height="10" transform="rotate(180 75 262)" fill="#ECEFF1" stroke="#607D8B"
                  strokeWidth="2"/>
            <path d="M72 272L72 258" stroke="#607D8B" strokeWidth="2"/>
            <path d="M188 100L188 328M180 100L196 100" stroke="#607D8B" strokeWidth="2"/>
            <path d="M104 80H180V120H104V80Z" fill="#E91E63" stroke="#607D8B" strokeWidth="2"/>
            <rect x="140" y="81" width="39" height="38" fill="#F06292"/>
            <path d="M104 72H180V80H104V72Z" fill="#ECEFF1" stroke="#607D8B" strokeWidth="2"/>
            <path d="M104 120H180V128H104V120Z" fill="#ECEFF1" stroke="#607D8B" strokeWidth="2"/>
            <rect x="125" y="86" width="6" height="10" fill="#ECEFF1" stroke="#607D8B" strokeWidth="2"/>
            <rect x="125" y="104" width="6" height="10" fill="#ECEFF1" stroke="#607D8B" strokeWidth="2"/>
            <path d="M128 76L128 90" stroke="#607D8B" strokeWidth="2"/>
            <rect x="101" y="86" width="6" height="10" fill="#ECEFF1" stroke="#607D8B" strokeWidth="2"/>
            <path d="M128 110L128 124" stroke="#607D8B" strokeWidth="2"/>
            <rect x="153" y="86" width="6" height="10" fill="#ECEFF1" stroke="#607D8B" strokeWidth="2"/>
            <rect x="101" y="104" width="6" height="10" fill="#ECEFF1" stroke="#607D8B" strokeWidth="2"/>
            <rect x="177" y="84" width="6" height="10" fill="#ECEFF1" stroke="#607D8B" strokeWidth="2"/>
            <rect x="153" y="104" width="6" height="10" fill="#ECEFF1" stroke="#607D8B" strokeWidth="2"/>
            <path d="M104 81V90" stroke="#607D8B" strokeWidth="2"/>
            <path d="M156 76L156 90" stroke="#607D8B" strokeWidth="2"/>
            <rect x="177" y="105" width="6" height="10" fill="#ECEFF1" stroke="#607D8B" strokeWidth="2"/>
            <path d="M104 110L104 120" stroke="#607D8B" strokeWidth="2"/>
            <path d="M156 110L156 124" stroke="#607D8B" strokeWidth="2"/>
            <path d="M180 76L180 90" stroke="#607D8B" strokeWidth="2"/>
            <path d="M180 110V124" stroke="#607D8B" strokeWidth="2"/>
            <path d="M196 80H272V120H196V80Z" fill="#E91E63" stroke="#607D8B" strokeWidth="2"/>
            <rect x="232" y="81" width="39" height="38" fill="#F06292"/>
            <path d="M196 72H272V80H196V72Z" fill="#ECEFF1" stroke="#607D8B" strokeWidth="2"/>
            <path d="M196 120H272V128H196V120Z" fill="#ECEFF1" stroke="#607D8B" strokeWidth="2"/>
            <rect x="217" y="86" width="6" height="10" fill="#ECEFF1" stroke="#607D8B" strokeWidth="2"/>
            <rect x="217" y="104" width="6" height="10" fill="#ECEFF1" stroke="#607D8B" strokeWidth="2"/>
            <path d="M220 76L220 90" stroke="#607D8B" strokeWidth="2"/>
            <rect x="193" y="86" width="6" height="10" fill="#ECEFF1" stroke="#607D8B" strokeWidth="2"/>
            <path d="M220 110L220 124" stroke="#607D8B" strokeWidth="2"/>
            <rect x="245" y="86" width="6" height="10" fill="#ECEFF1" stroke="#607D8B" strokeWidth="2"/>
            <rect x="193" y="104" width="6" height="10" fill="#ECEFF1" stroke="#607D8B" strokeWidth="2"/>
            <rect x="269" y="84" width="6" height="10" fill="#ECEFF1" stroke="#607D8B" strokeWidth="2"/>
            <rect x="245" y="104" width="6" height="10" fill="#ECEFF1" stroke="#607D8B" strokeWidth="2"/>
            <path d="M196 81V90" stroke="#607D8B" strokeWidth="2"/>
            <path d="M248 76L248 90" stroke="#607D8B" strokeWidth="2"/>
            <rect x="269" y="105" width="6" height="10" fill="#ECEFF1" stroke="#607D8B" strokeWidth="2"/>
            <path d="M196 110L196 120" stroke="#607D8B" strokeWidth="2"/>
            <path d="M248 110L248 124" stroke="#607D8B" strokeWidth="2"/>
            <path d="M272 76L272 90" stroke="#607D8B" strokeWidth="2"/>
            <path d="M272 110V124" stroke="#607D8B" strokeWidth="2"/>
            <path
                d="M288.5 191L288 276M288 328L288 309M336.5 324.5L288 276M288 276L241 324M288 276L288 309M268 296L288 309M288 309L307.5 296"
                stroke="#607D8B" strokeWidth="2"/>
            <path d="M240 144H336V184H240V144Z" fill="#E91E63" stroke="#607D8B" strokeWidth="2"/>
            <rect x="280" y="145" width="55" height="38" fill="#F06292"/>
            <path d="M240 136H336V144H240V136Z" fill="#ECEFF1" stroke="#607D8B" strokeWidth="2"/>
            <path d="M240 184H336V192H240V184Z" fill="#ECEFF1" stroke="#607D8B" strokeWidth="2"/>
            <rect x="265" y="150" width="6" height="10" fill="#ECEFF1" stroke="#607D8B" strokeWidth="2"/>
            <rect x="265" y="168" width="6" height="10" fill="#ECEFF1" stroke="#607D8B" strokeWidth="2"/>
            <path d="M268 140L268 154" stroke="#607D8B" strokeWidth="2"/>
            <rect x="237" y="150" width="6" height="10" fill="#ECEFF1" stroke="#607D8B" strokeWidth="2"/>
            <path d="M268 174L268 188" stroke="#607D8B" strokeWidth="2"/>
            <rect x="301" y="150" width="6" height="10" fill="#ECEFF1" stroke="#607D8B" strokeWidth="2"/>
            <rect x="237" y="168" width="6" height="10" fill="#ECEFF1" stroke="#607D8B" strokeWidth="2"/>
            <rect x="333" y="148" width="6" height="10" fill="#ECEFF1" stroke="#607D8B" strokeWidth="2"/>
            <rect x="301" y="168" width="6" height="10" fill="#ECEFF1" stroke="#607D8B" strokeWidth="2"/>
            <path d="M240 145V154" stroke="#607D8B" strokeWidth="2"/>
            <path d="M304 140L304 154" stroke="#607D8B" strokeWidth="2"/>
            <rect x="333" y="169" width="6" height="10" fill="#ECEFF1" stroke="#607D8B" strokeWidth="2"/>
            <path d="M240 174L240 184" stroke="#607D8B" strokeWidth="2"/>
            <path d="M304 174L304 188" stroke="#607D8B" strokeWidth="2"/>
            <path d="M336 140L336 154" stroke="#607D8B" strokeWidth="2"/>
            <path d="M336 174V188" stroke="#607D8B" strokeWidth="2"/>
            <path d="M232 328H248V320L244 316L232 328Z" fill="#607D8B"/>
            <path d="M344.5 328H328V320L332 316L344.5 328Z" fill="#607D8B"/>
            <path d="M292 316H284L280 328H296L292 316Z" fill="#607D8B"/>
            <path d="M356 96V76" stroke="#607D8B" strokeWidth="2"/>
            <path
                d="M367 102C367 106.808 362.254 111 356 111C349.746 111 345 106.808 345 102C345 97.1924 349.746 93 356 93C362.254 93 367 97.1924 367 102Z"
                fill="#FFC107" stroke="#607D8B" strokeWidth="2"/>
            <path
                d="M403 106C403 106.334 402.839 106.769 402.298 107.307C401.756 107.849 400.903 108.419 399.715 108.992C397.344 110.136 393.848 111.193 389.448 112.091C380.665 113.884 368.485 115 355 115C341.515 115 329.335 113.884 320.552 112.091C316.152 111.193 312.656 110.136 310.285 108.992C309.097 108.419 308.244 107.849 307.702 107.307C307.161 106.769 307 106.334 307 106C307 105.666 307.161 105.231 307.702 104.693C308.244 104.151 309.097 103.581 310.285 103.008C312.656 101.864 316.152 100.807 320.552 99.9087C329.335 98.1161 341.515 97 355 97C368.485 97 380.665 98.1161 389.448 99.9087C393.848 100.807 397.344 101.864 399.715 103.008C400.903 103.581 401.756 104.151 402.298 104.693C402.839 105.231 403 105.666 403 106Z"
                fill="#FFC107" stroke="#607D8B" strokeWidth="2"/>
            <path
                d="M403 111C403 111.334 402.839 111.769 402.298 112.307C401.756 112.849 400.903 113.419 399.715 113.992C397.344 115.136 393.848 116.193 389.448 117.091C380.665 118.884 368.485 120 355 120C341.515 120 329.335 118.884 320.552 117.091C316.152 116.193 312.656 115.136 310.285 113.992C309.097 113.419 308.244 112.849 307.702 112.307C307.161 111.769 307 111.334 307 111C307 110.666 307.161 110.231 307.702 109.693C308.244 109.151 309.097 108.581 310.285 108.008C312.656 106.864 316.152 105.807 320.552 104.909C329.335 103.116 341.515 102 355 102C368.485 102 380.665 103.116 389.448 104.909C393.848 105.807 397.344 106.864 399.715 108.008C400.903 108.581 401.756 109.151 402.298 109.693C402.839 110.231 403 110.666 403 111Z"
                fill="#FFC107" stroke="#607D8B" strokeWidth="2"/>
            <path
                d="M355.5 114L356 276M356 328L356 307.5M404 324L356 276M356 276L336 296M356 276L356 307.5M308 324L336 296M336 296L356 307.5M356 307.5L375.5 296"
                stroke="#607D8B" strokeWidth="2"/>
            <rect x="361" y="79" width="6" height="10" fill="#ECEFF1" stroke="#607D8B" strokeWidth="2"/>
            <path d="M352 84L362 84" stroke="#607D8B" strokeWidth="2"/>
            <path d="M300 328H316V320L312 316L300 328Z" fill="#607D8B"/>
            <path d="M412 328H396V320L400 316L412 328Z" fill="#607D8B"/>
            <path d="M360 316H352L348 328H364L360 316Z" fill="#607D8B"/>
            <ellipse cx="187.5" cy="236" rx="92.5" ry="92" fill="#F06292" stroke="#607D8B" strokeWidth="2"/>
            <path
                d="M201 146.111C196.321 145.38 191.525 145 186.639 145C136.028 145 95 185.742 95 236C95 286.258 136.028 327 186.639 327C191.345 327 195.967 326.648 200.482 325.969C183.871 300.068 174.256 269.363 174.256 236.441C174.256 203.178 184.072 172.179 201 146.111Z"
                fill="#E91E63"/>
            <ellipse cx="188" cy="236.5" rx="84" ry="83.5" fill="#ECEFF1" stroke="#607D8B" strokeWidth="2"/>
            <path
                d="M186.646 318.992C172.858 294.58 165 266.447 165 236.5C165 206.553 172.858 178.42 186.646 154.008C187.025 154.003 187.405 154 187.785 154C233.743 154 271 190.937 271 236.5C271 282.063 233.743 319 187.785 319C187.405 319 187.025 318.997 186.646 318.992Z"
                fill="white"/>
            <rect x="108.462" y="193.712" width="6" height="10" transform="rotate(-148.654 108.462 193.712)"
                  fill="#ECEFF1"
                  stroke="#607D8B" strokeWidth="2"/>
            <rect x="266.46" y="289.95" width="6" height="10" transform="rotate(-148.654 266.46 289.95)" fill="#ECEFF1"
                  stroke="#607D8B" strokeWidth="2"/>
            <rect x="98" y="241" width="6" height="10" transform="rotate(180 98 241)" fill="#ECEFF1" stroke="#607D8B"
                  strokeWidth="2"/>
            <rect x="283" y="241" width="6" height="10" transform="rotate(180 283 241)" fill="#ECEFF1" stroke="#607D8B"
                  strokeWidth="2"/>
            <rect x="115.439" y="289.315" width="6" height="10" transform="rotate(146.701 115.439 289.315)"
                  fill="#ECEFF1"
                  stroke="#607D8B" strokeWidth="2"/>
            <rect x="270.066" y="187.75" width="6" height="10" transform="rotate(146.701 270.066 187.75)" fill="#ECEFF1"
                  stroke="#607D8B" strokeWidth="2"/>
            <rect x="147.243" y="155.909" width="6" height="10" transform="rotate(-113.488 147.243 155.909)"
                  fill="#ECEFF1"
                  stroke="#607D8B" strokeWidth="2"/>
            <rect x="220.977" y="325.58" width="6" height="10" transform="rotate(-113.488 220.977 325.58)"
                  fill="#ECEFF1"
                  stroke="#607D8B" strokeWidth="2"/>
            <rect x="221.038" y="152.871" width="6" height="10" transform="rotate(-64.8313 221.038 152.871)"
                  fill="#ECEFF1"
                  stroke="#607D8B" strokeWidth="2"/>
            <rect x="142.36" y="320.307" width="6" height="10" transform="rotate(-64.8313 142.36 320.307)"
                  fill="#ECEFF1"
                  stroke="#607D8B" strokeWidth="2"/>
        </g>
    </>
}