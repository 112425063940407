import {Session, User} from 'next-auth'

export function isAdmin(session: Session | null) {
    return isAdminUser(session?.user)
}

export function isAdminUser(user: User | null) {
    return user?.id == '2DO5QJ4lUUlMKaHzvna4y'
}

