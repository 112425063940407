import {DrumKit} from '@ezchops/base-components/dist/components/drumkit'
import {FeaturedLessonCard} from '@ezchops/base-components/dist/components/lesson/FeaturedLessonCard'
import {drummerManager} from '@ezchops/datastore'
import {CourseLessonMeta, LessonProgress} from '@ezchops/model/lib/lesson'
import {Box, colors, Container, useMediaQuery} from '@mui/material'
import Stack, {StackProps} from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Unstable_Grid2'
import {ConnectedHoriz, ConnectedVert, Hero} from 'components/v3/home'
import {Authorization} from 'lib/auth'
import {getProtectedServerSideProps, ProtectedPage, ProtectedPageProps} from 'lib/auth/client'
import {GetServerSidePropsContext} from 'next'
import {Session, User} from 'next-auth'
import Link from 'next/link'
import React from 'react'
import {pathwayManager} from '../src/lib/datastore'


export default ProtectedPage(function Home(
    {lessons}: ProtectedPageProps<typeof getServerSideProps>) {
    return <Container disableGutters={true} maxWidth={false}>
        <Hero/>
        <Features/>
        <FeaturedLessons lessons={lessons}/>
        {/*<HowToUse/>*/}
        <Footer/>
    </Container>
})


function Features() {
    const horiz = useMediaQuery('(min-width:1024px)')
    return <Stack alignItems="center" direction="column" sx={{mt: 4, pt: 4, height: horiz ? '100vh' : undefined}}>
        <Typography variant="h2" sx={{fontSize: '3rem', mb: 2, textAlign: 'center', color: colors.amber[500]}}>
            Csatlakoztasd&nbsp;a&nbsp;dobodat, és élvezd a gyakorlást!
        </Typography>
        {horiz ? <ConnectedHoriz/> : <ConnectedVert/>}
    </Stack>
}

function FeaturedLessons({lessons}: { lessons: { lesson: CourseLessonMeta, userAuthorized: boolean, progress: LessonProgress }[] }) {
    return <Section id="featured" sx={{backgroundColor: colors.blueGrey[50], pt: 10, pb: 10}}>
        <Typography variant="h2" sx={{mb: 2, textAlign: 'center', color: colors.pink[500]}}>
            Válaszd ki, mit játszanál!
        </Typography>
        <Box sx={{flexGrow: 1}}>
            <Grid spacing={2} container>{lessons.map(
                ({lesson, progress, userAuthorized}, i) =>
                    <Grid key={i} xs={12} md={4} lg={3}>
                        <FeaturedLessonCard lesson={lesson} progress={progress} userAuthorized={userAuthorized}/>
                    </Grid>
            )}</Grid>
        </Box>
    </Section>
}

function HowToUse() {
    return <Section sx={{pt: 10, pb: 10}}>
        <Typography variant="h2" sx={{mb: 2, textAlign: 'center', color: colors.amber[500]}}>
            Mutatjuk, hogyan használd!
        </Typography>
        <Typography variant="body1" sx={{textAlign: 'center'}}>
            Színekkel segítünk eligazodni, melyik dobot, cintányért<br/> üssed vagy taposót használd.
        </Typography>
        <DrumKit/>
    </Section>
}

function Footer() {
    return <Section sx={{pt: 10, pb: 10}}>
        <Stack direction="row" justifyContent="center" alignItems="flex-start" spacing={4}>
            <Stack direction="column" alignItems="flex-start">
                <Typography variant="body2">dobolj.hu</Typography>
            </Stack>
            <Stack direction="column" alignItems="flex-start">
                <Typography variant="body2">
                    Kapcsolat<br/>
                    <Link href="mailto:info@dobolj.hu">info@dobolj.hu</Link>
                </Typography>
            </Stack>
            {/*<Stack direction="column" alignItems="flex-start">*/}
            {/*    <Typography variant="body2">*/}
            {/*        Adatvédelmi nyilatkozat<br/>*/}
            {/*        Felhasználási feltételek<br/>*/}
            {/*    </Typography>*/}
            {/*</Stack>*/}
        </Stack>
    </Section>
}

function Section({children, sx, ...props}: Omit<StackProps, 'direction'>) {
    return <Stack direction="column" sx={sx} {...props}>
        <Container maxWidth="lg">
            {children}
        </Container>
    </Stack>
}

export const getServerSideProps = async (context: GetServerSidePropsContext) => {
    const getProps = async (session: Session | null, user: User | null) => {
        const drummer = user != null ? await drummerManager.readUser(user.id) : null
        const nextLessons = await pathwayManager.nextLessonsForUser(drummer)
        const lessons = await Promise.all(nextLessons.map(async ({lesson, progress}) => ({
            lesson, progress,
            userAuthorized: await Authorization.lesson.read(session, lesson),
        })))
        return {lessons}
    }

    return await getProtectedServerSideProps(getProps, Authorization.home.read, context)
}
